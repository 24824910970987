// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/Users/andrewkim/andrewj.kim/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/Users/andrewkim/andrewj.kim/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/Users/andrewkim/andrewj.kim/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("/Users/andrewkim/andrewj.kim/src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

